import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import LegalDoc from '../../modules/legal/components/legal-doc';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = function DPA({
  children
}) {
  return <LegalDoc title="Data Processing Addendum" updated="12 Feb 2019" downloadLink="Fidel_Data_Processing_Addendum.pdf">
      {children}
    </LegalDoc>;
};

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      